.results {
  &-container {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: var(--white);
    color: var(--dark);
  }
}

.displayResults {
  &-title {
    font-size: 3rem;
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 3rem;
    margin-bottom: 1.5rem;
  }

  &-date {
    margin: 5rem 0;
  }

  &-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 90%;

    & > * {
      //text-transform: lowercase;
    }

    & div:not(.displayResults-line, .displayResults-response-container) {
      //flex: 1;
    }
  }

  &-content {
    display: flex;
    margin: 1rem 0;
    font-size: 2rem;

    & span {
      font-size: 2rem;
      font-weight: 700;
      margin-right: 1rem;
    }
  }

  &-response {
    display: flex;
    align-items: center;
    font-size: 2rem;
    margin: 2rem 0;

    & svg {
      margin-right: 2rem;
    }
  }

  &-line {
    margin: 0 7rem;
    width: .5rem;
    height: 40rem;
    background: linear-gradient(230deg, $darkBlue, $lightBlue);
  }
}
