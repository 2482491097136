.navigation {
  background-color: var(--white);
  z-index: 2;
  height: 100svh;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  align-items: center;
  padding: 2rem 1.7rem 2rem 1.2rem;
  position: relative;

  &-hide {
    display: none;
  }

  & img {
      width: 8rem;
      margin-bottom: 3.5rem;
    }

    &::after {
      top: 0;
      right: 0;
      position: absolute;
      content:'';
      width: .5rem;
      height: 100%;
      background: linear-gradient(230deg, $darkBlue, $lightBlue);
    }

  &-link {
    display: flex;
    justify-content: center;
    padding: 1rem;
    text-decoration: none;
    text-transform: uppercase;
    border-radius: 1rem;
    width: 100%;
    margin: .5rem 0;

    &-activate {
      border: .15rem solid var(--white);

      & div {
        font-size: 1.5rem;
        color: $darkBlue;
      }

      &:hover, &.active {
        background: linear-gradient(230deg, $darkBlue, $lightBlue);
        border: .15rem solid var(--white);

        & div {
          color: white;
        }
      }
    }

    &-deactivate {
      & div {
        font-size: 1.5rem;
        color: $lightDarkBlue;
      }

      &:hover, &.active {
        cursor: not-allowed;

        & div {
          color: $lightDarkBlue;
        }
      }
    }
  }

  &-start {
    height: 9.5rem;
  }

  &-navlink-container {
    height: 80%;
    margin: 0;
  }

  &-footer {
    width: 90%;
    display: flex;
    justify-content: space-between;

    &-profile {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      & a {
        margin-bottom: .5rem;
      }
    }

    &-status {
      & svg {
        font-size: 1.2rem;
        margin-right: .3rem;
      }

      font-size: 1.2rem;
      white-space: nowrap;
      position: absolute;
      bottom: -1.2rem;
      left: 0;
    }

    &-logout {
      margin-top: .3rem;
    }

    & .icon {
      font-size: 3rem;
      transform: rotate(0);
    }

    & .navigation-profile {
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      text-transform: uppercase;
      font-size: 3rem;
      height: 4rem;
      border-radius: .5rem;
      width: 4rem;
      color: $darkBlue;
      border: .15rem solid $darkBlue;

      &:hover, &.active {
        color: var(--white);
        border: .15rem solid var(--white);
        background: linear-gradient(230deg, $darkBlue, $lightBlue);
      }
    }
  }

  &-left {
    background-color: var(--white);
    position: fixed;
    display: flex;
    align-items: center;
    right: 0;
    z-index: 20000;
    height: 100svh;
    transform: translateX(100%);
    transition: transform 300ms ease-in-out;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    padding: 1rem 2rem;

    &-open {
      transition: transform 300ms ease-in-out;
      transform: translateX(0);
      box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

      & .navigation-left-icon {
        transition: transform 300ms ease-in-out;
        transform: rotate(0);
      }
    }

    &-icon {
      height: 2rem;
      width: 2rem;
      margin-right: -.5rem;
      padding: 0;
      transition: transform 300ms ease-in-out;
      transform: rotate(180deg);

      path {
        fill: var(--white);
      }
    }

    &-btn {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: -4.5rem;
      z-index: 3;
      width: 2.5rem;
      height: 5rem;
      border-radius: 2.5rem 0 0 2.5rem;
      background: linear-gradient(230deg, $darkBlue, $lightBlue);
      box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    }

    &-content {
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    &-title {
      margin: .5rem 0 2.5rem 0;
      font-size: 2.5rem;
      font-weight: 700;
      text-transform: uppercase;
      color: var(--dark);
    }

    &-subtitle {
      font-size: 1.5rem;
      margin-bottom: 1rem;
      text-transform: uppercase;
      color: var(--dark)
    }

    &-top {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 50%;

      & .icon {
        font-size: 4rem;
      }

      &-current, &-serial {
        width: 100%;
        margin-bottom: 1rem;
      }

      &-container {
        margin-bottom: 2rem;
      }

      &-container, &-content {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &-content {
        padding: 0 1rem;
        font-size: 1.5rem;
        color: var(--dark);

        & .icon {
          margin-right: .5rem;
        }
      }
    }

    &-bottom {
      border-top: .3rem solid var(--dark);
      padding-top: 1.5rem ;
      display: flex;
      flex-direction: column;
      justify-content: end;
      align-items: center;
    }
  }
}
