.reading {
  &-form {
    & .game-radio-container {
      & label {
        &.type {
          height: 6rem;
          width: 6rem;
        }

        &.textType {
          height: 5rem;
          width: 10rem;
        }

        &.target, &.typeComparison {
          width: 12rem;
          height: 5rem;
        }
      }

      & .rText {
        & span {
          font-size: 2rem;
        }
      }
    }

    & .MuiSlider-root .MuiSlider-mark[data-index="1"] {
      height: 0.5rem;
      width: 0.5rem;
      border-radius: 0.5rem;
      background-color: var(--dark);

      &.MuiSlider-markActive {
        background-color: var(--white);
      }
    }
  }

  &-text {
    max-width: 86svw;
    max-height: calc(98.5svh);
    overflow: hidden;
    color: var(--dark);

    & span {
      font-size: inherit;
      font-family: inherit;
    }

    &.tc-1, &.tc-0 {
      & span {
        color: $red;
      }
    }
  }

  &-game {
    width: 100%;
    height: 100svh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: hidden;

    &.noGroup {
      & .rgd-real-word svg {
        padding-right: 0!important;
        padding-left: 0!important;
      }

      & .reading-noAnimate span {
        letter-spacing: 0!important;
      }
    }
  }

  &-game-display {
    display: flex;
    flex-direction: column;
    align-items: center;

    &.appearance {
      display: grid;
      grid-template-rows: repeat(15, calc(100svh / 15));
      grid-template-columns: repeat(15, calc(86svw / 15));
      overflow-x: visible;

      &.wordType-2 {
        & .grid:nth-child(10), & .grid:nth-child(n+25):nth-child(15n+10),
        & .grid:nth-child(12), & .grid:nth-child(n+27):nth-child(15n+12),
        & .grid:nth-child(15n) {
          justify-content: flex-end;
        }
      }

      & .grid {
        display: flex;
        align-items: center;
        height: calc(100svh / 15);

        & span {
          color: var(--dark);
        }
      }

      &.wordType-3 {
        grid-template-columns: repeat(6, calc(86svw / 6));
        grid-template-rows: repeat(14, calc(100svh / 14));

        & .grid {
          height: calc(100svh / 14);
        }
      }

      &.withGrid {
        & .grid:not(:nth-last-child(-n+15)) {
          border-bottom: .1rem solid var(--dark);
        }

        &.wordType-3 {
          & .grid:not(:nth-last-child(-n+6)) {
            border-bottom: .1rem solid var(--dark);
          }
        }
      }
    }

    & .blank {
      height: 9rem;
      padding: .5rem 0;
      width: 30rem;
      background-color: var(--white);
      margin: 0 auto;
      position: absolute;
      z-index: 1000;

      &-left {
        left: 0;
        padding-left: 35svw;
        border-right: .4rem solid var(--dark);
      }

      &-right {
        right: 0;
        padding-right: 35svw;
        border-left: .4rem solid var(--dark);
      }
    }

    & .rgd {
      &-real-word {
        padding: .5rem 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--white);
        z-index: 2;
        border: solid .2rem transparent;
        min-height: 5rem;
        color: var(--dark);

        & span {
          color: var(--dark)
        }

        &.hide {
          position: absolute;
          top: 10.5svh;

          &:hover {
            & span {
              color: var(--dark)
            }
          }

          & span {
            color: var(--white)
          }
        }
      }

      &-fake-word {
        &-container {
          position: absolute;
          bottom: 12svh;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(2, 1fr);
          background-color: var(--dark);
          grid-gap: .1rem;
          margin: 0;

          &.hide {
            background: var(--white);
          }
        }

        &-content {
          background: var(--white);
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          padding: 3rem;
          color: var(--dark);

          &:hover {
            color: $darkBlue;

            & span span {
              color: $darkBlue!important;
            }

            & svg path {
              fill: $darkBlue!important;
            }
          }

          &.hide {
            color: var(--white);
          }

          &.true, & .true {
            color: #339900;
            pointer-events:none;
            cursor: default;

            &:hover {
              color: #339900;

              & span span {
                color: #339900!important;
              }

              & svg path {
                fill: #339900!important;
              }
            }
          }

          &.false, & .false {
            cursor: default;
            color: $red;
            pointer-events:none;

            &:hover {
              color: $red;

              & span span {
                color: $red!important;
              }

              & svg path {
                fill: $red!important;
              }
            }
          }
        }
      }
    }
  }

  &-animate-container {
    height: 9rem;
    width: 100%;
    position: fixed;
    top: 9svh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-noAnimate {
    position: fixed;
    top: 10.5svh;
  }
}
