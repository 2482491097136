html, body, p, ol, ul, li, dl, dt, dd, blockquote, figure, fieldset, legend, textarea, pre, iframe, hr, h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal
}

ul {
  list-style: none
}

button, input, select {
  margin: 0
}

html {
  box-sizing: border-box
}

*, *::before, *::after {
  box-sizing: inherit
}

img, video {
  height: auto;
  max-width: 100%
}

iframe {
  border: 0
}

table {
  border-collapse: collapse;
  border-spacing: 0
}

td, th {
  padding: 0
}

* {
  font-size: 10px;
  font-family: Roboto, sans-serif;
  font-weight: 400;
}

main {
  width: 100%;
  height: 100svh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.icon {
  font-size: 5rem;

  & path {
    fill: url(#linear-gradient);
  }

  &:hover {
    cursor: pointer;
  }

  &-false {
    & path {
      fill: url(#linear-gradient-false);
    }
  }

  &-true {
    & path {
      fill: url(#linear-gradient-true);
    }
  }
}

button {
  background-color: transparent;
  border: none;

  &:hover {
    cursor: pointer;
  }
}

.cursive {
  font-family: parisienne, sans-serif;
}

//disappear blue square
* {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.slider-time {
  &-900, &-1000, &-1200, &-2500, &-4000, &-7000, &-8000, &-2000 {
    width: 40rem;
    margin: 2rem 1.5rem 1.5rem 1.5rem;
  }

  &-1500 {
    width: 55rem;
    margin: 2rem 1.5rem 1.5rem 1.5rem;
  }

  &.defaultValue {
    .MuiSlider-root .MuiSlider-thumb {
      border-color: #D21404;
    }
  }
}

.main {
  &-container {
    display: flex;
    background-color: var(--white)
  }
}

.btn-nav {
  position: fixed!important;
  top: calc(100svh - 5rem - 2rem);

  &.btn-first {
    left: 2rem;
    padding: 0;

    & .icon {
      &:hover {
        & path {
          d: path("M 48 256 a 208 208 0 1 1 416 0 A 208 208 0 1 1 48 256 Z m 464 0 A 256 256 0 1 0 0 256 a 256 256 0 1 0 512 0 Z M 217.4 376.9 c 4.2 4.5 10.1 7.1 16.3 7.1 c 12.3 0 22.3 -10 22.3 -22.3 V 304 h 96 c 17.7 0 32 -14.3 32 -32 V 240 c 0 -17.7 -14.3 -32 -32 -32 H 256 V 150.3 c 0 -12.3 -10 -22.3 -22.3 -22.3 c -6.2 0 -12.1 2.6 -16.3 7.1 L 117.5 242.2 c -3.5 3.8 -5.5 8.7 -5.5 13.8 s 2 10.1 5.5 13.8 l 99.9 107.1 Z");        }
      }
    }
  }

  &.btn-correction {
    display: flex;
    flex-direction: column;
    top: calc(100svh - 2rem - 18rem);

    & button {
      padding: .6rem 0;

      &:last-child {
        padding-bottom: 0;
      }
    }
  }

  &.btn-second {
    right: 2rem;
    padding: 0;

    & .icon {
      &:hover {
        & path {
          d: path('M280.2 150.2C273.1 143.8 262.1 142.2 254.3 146.1S239.1 158.5 239.1 167.1l.002 56L152 224C138.8 224 128 234.8 128 248v16C128 277.3 138.8 288 152 288L240 287.1v56c0 9.531 5.656 18.16 14.38 22c8.75 3.812 18.91 2.094 25.91-4.375l96-88.75C381.2 268.3 384 261.9 384 255.2c-.3125-7.781-2.875-13.25-7.844-17.75L280.2 150.2zM256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 464c-114.7 0-208-93.31-208-208S141.3 48 256 48s208 93.31 208 208S370.7 464 256 464z')!important;
        }
      }

      &-false {
        &:hover {
          & path {
            d: path('M175 175C184.4 165.7 199.6 165.7 208.1 175L255.1 222.1L303 175C312.4 165.7 327.6 165.7 336.1 175C346.3 184.4 346.3 199.6 336.1 208.1L289.9 255.1L336.1 303C346.3 312.4 346.3 327.6 336.1 336.1C327.6 346.3 312.4 346.3 303 336.1L255.1 289.9L208.1 336.1C199.6 346.3 184.4 346.3 175 336.1C165.7 327.6 165.7 312.4 175 303L222.1 255.1L175 208.1C165.7 199.6 165.7 184.4 175 175V175zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z') !important;
          }
        }
      }

      &-true {
        &:hover {
          & path {
            d: path('M243.8 339.8C232.9 350.7 215.1 350.7 204.2 339.8L140.2 275.8C129.3 264.9 129.3 247.1 140.2 236.2C151.1 225.3 168.9 225.3 179.8 236.2L224 280.4L332.2 172.2C343.1 161.3 360.9 161.3 371.8 172.2C382.7 183.1 382.7 200.9 371.8 211.8L243.8 339.8zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z')!important;
          }
        }
      }

      &-missed {
        &:hover {
          & path {
            d: path("M 48 256 C 48 141.12 141.12 48 256 48 s 208 93.12 208 208 S 370.88 464 256 464 S 48 370.88 48 256 Z m 464 0 C 512 114.62 397.38 0 256 0 S 0 114.62 0 256 S 114.62 512 256 512 S 512 397.38 512 256 Z m -280 8 V 152 a 24 24 0 0 1 48 0 V 264 a 24 24 0 0 1 -48 0 Z m 56 88 a 32 32 0 1 1 -32 -32 A 32 32 0 0 1 288 352 Z")!important;
          }
        }
      }
    }
  }

  &.btn-center {
    z-index: -1;
    left: 50%;
    transform: translateX(-50%);

    & .icon {
      &-center {
        &:hover {
          & path {
            d: path('M224 256c-35.2 0-64 28.8-64 64c0 35.2 28.8 64 64 64c35.2 0 64-28.8 64-64C288 284.8 259.2 256 224 256zM433.1 129.1l-83.9-83.9C341.1 37.06 328.8 32 316.1 32H64C28.65 32 0 60.65 0 96v320c0 35.35 28.65 64 64 64h320c35.35 0 64-28.65 64-64V163.9C448 151.2 442.9 138.9 433.1 129.1zM128 80h144V160H128V80zM400 416c0 8.836-7.164 16-16 16H64c-8.836 0-16-7.164-16-16V96c0-8.838 7.164-16 16-16h16v104c0 13.25 10.75 24 24 24h192C309.3 208 320 197.3 320 184V83.88l78.25 78.25C399.4 163.2 400 164.8 400 166.3V416z')!important;
          }
        }
      }
    }
  }

  &:disabled {
    opacity: 50%;

    & .icon {
      &:hover {
        cursor: not-allowed;
        & path {
          d: path("M0 256C0 397.4 114.6 512 256 512s256-114.6 256-256S397.4 0 256 0S0 114.6 0 256zm395.3 11.3l-112 112c-4.6 4.6-11.5 5.9-17.4 3.5s-9.9-8.3-9.9-14.8l0-64-96 0c-17.7 0-32-14.3-32-32l0-32c0-17.7 14.3-32 32-32l96 0 0-64c0-6.5 3.9-12.3 9.9-14.8s12.9-1.1 17.4 3.5l112 112c6.2 6.2 6.2 16.4 0 22.6z") !important}
      }
    }
  }
}

.cursor-hide {
  cursor: none;

  & > * {
    pointer-events: none;
  }
}

@media screen and (max-width: 560px) {
  * {
    font-size: 7px;
  }

  .btn-nav {
    bottom: 10rem
  }
}

.title {
  top: 2rem;
  position: absolute;
  display: flex;
  justify-content: flex-end;
  padding-right: 2rem;
  width: 100%;
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;

  & svg {
    height: 2.5rem;
    width: 2.5rem;
    margin-left: 1rem;
    cursor: pointer;
    color: var(--dark);
  }
}

.MuiTooltip-tooltip {
  font-size: 1.4rem !important;
  text-align: justify;
  color: var(--dark)!important;
  background-color: var(--white)!important;
  border: .1rem solid var(--dark);
  line-height: 2rem!important;
}

.Toastify__toast-body div {
  font-size: 1.5rem;
}

.btn-insta {
  //position: absolute;
  //top: 2rem;
  //left: 3rem;

  margin-left: 3rem;
  margin-top: 1rem;

  & svg {
    font-size: 3rem;

    & path {
      fill: var(--dark);
    }
  }
}

.btn-download {
  //position: absolute;
  //top: 2rem;
  //right: 3rem;

  margin-right: 3rem;
  margin-top: 1rem;

  & a:first-child {
    margin: 0 2rem;
  }

  & svg {
    font-size: 3rem;

    & path {
      fill: var(--dark);
    }
  }
}

input {
  -webkit-user-select:text;
}

.size {
  &-1 .content, &-1 .rgd-real-word:not(.hide) {
   font-size: 2.4rem!important;
  }

  &-2 .content, &-2 .rgd-real-word:not(.hide) {
    font-size: 2.9rem!important;
  }

  &-3 .content, &-3 .rgd-real-word:not(.hide) {
    font-size: 3.4rem!important;
  }

  &-1-zoom .content, &-1-zoom .rgd-real-word:not(.hide) {
    font-size: 4.4rem!important;
  }

  &-2-zoom .content, &-2-zoom .rgd-real-word:not(.hide) {
    font-size: 4.9rem!important;
  }

  &-3-zoom .content, &-3-zoom .rgd-real-word:not(.hide) {
    font-size: 5.4rem!important;
  }
}

.contrast {
  &-1 .content, &-1 .rgd-real-word:not(.hide) {
    color: rgba(0, 0, 0, .3);
  }

  &-2 .content, &-2 .rgd-real-word:not(.hide) {
    color: rgba(0, 0, 0, .5);
  }
}

@media only screen and (hover: none) and (pointer: coarse){
  .btn-nav {
    bottom: 10rem
  }
}
