.MuiSlider-root {
  & .MuiSlider-rail {
    height: .8rem;
    background-color: $darkBlue;
  }

  & .MuiSlider-track {
    background: linear-gradient(230deg, #4678F3, #64D6EE);
    border: none;
  }

  & .MuiSlider-thumb {
    background: var(--white);
    border: .2rem solid $darkBlue;

    & input[value="200"]+.MuiSlider-valueLabelOpen,
    & input[value="400"]+.MuiSlider-valueLabelOpen,
    & input[value="600"]+.MuiSlider-valueLabelOpen,
    & input[value="800"]+.MuiSlider-valueLabelOpen,
    & input[value="1000"]+.MuiSlider-valueLabelOpen,
    & input[value="1200"]+.MuiSlider-valueLabelOpen,
    & input[value="1400"]+.MuiSlider-valueLabelOpen,
    & input[value="1600"]+.MuiSlider-valueLabelOpen,
    & input[value="2000"]+.MuiSlider-valueLabelOpen,
    & input[value="3000"]+.MuiSlider-valueLabelOpen,
    & input[value="4000"]+.MuiSlider-valueLabelOpen,
    & input[value="6000"]+.MuiSlider-valueLabelOpen,
    & input[value="8000"]+.MuiSlider-valueLabelOpen,
    {
      display: none;
    }
  }

  & .MuiSlider-markLabel {
    color: $darkBlue;
    font-size: 1.8rem;
  }

  & .MuiSlider-valueLabel {
    background-color: transparent;
  }

  & .MuiSlider-valueLabelLabel {
    color: $darkBlue;
    font-size: 1.8rem;
    display: block;
    margin-bottom: -.6rem;

    &:after {
      content: 'ms'
    }
  }

  & .MuiSlider-mark {
    height: 0.5rem;
    width: 0.5rem;
    border-radius: .5rem;
  }
}

.empan-form {
  & .MuiSlider-mark {
    &[data-index="2"] {
      background-color: #D21404;
      border-radius: .5rem;
      margin-left: -.25rem;
      height: 1rem;
      width: 1rem;
    }
  }

  & .MuiSlider-thumb {
    & input[value="250"]+.MuiSlider-valueLabelOpen .MuiSlider-valueLabelCircle .MuiSlider-valueLabelLabel {
      color: #D21404;
    }
  }
}

.slider-time-900, .slider-time-1500 {
  & .MuiSlider-thumb {
    & input[value="200"]+.MuiSlider-valueLabelOpen,
    & input[value="400"]+.MuiSlider-valueLabelOpen,
    & input[value="600"]+.MuiSlider-valueLabelOpen,
    & input[value="800"]+.MuiSlider-valueLabelOpen,
    & input[value="1000"]+.MuiSlider-valueLabelOpen,
    & input[value="1200"]+.MuiSlider-valueLabelOpen,
    & input[value="1400"]+.MuiSlider-valueLabelOpen,
    & input[value="1600"]+.MuiSlider-valueLabelOpen,
    & input[value="2000"]+.MuiSlider-valueLabelOpen,
    & input[value="3000"]+.MuiSlider-valueLabelOpen,
    & input[value="4000"]+.MuiSlider-valueLabelOpen
    {
      display: block;
    }

    & input[value="100"]+.MuiSlider-valueLabelOpen,
    & input[value="300"]+.MuiSlider-valueLabelOpen,
    & input[value="500"]+.MuiSlider-valueLabelOpen,
    & input[value="700"]+.MuiSlider-valueLabelOpen,
    & input[value="900"]+.MuiSlider-valueLabelOpen,
    & input[value="1100"]+.MuiSlider-valueLabelOpen,
    & input[value="1300"]+.MuiSlider-valueLabelOpen,
    & input[value="1500"]+.MuiSlider-valueLabelOpen,
    {
      display: none;
    }
  }
}

.ftb-container {
  margin: 1.5rem 0;
  display: flex;

  & .MuiToggleButtonGroup-root {
    margin: 0 3rem;
  }

  & .MuiButtonBase-root {
    height: 5rem;
    width: 5rem;
    padding: 0;
    border-radius: .5rem;
    border: .15rem $darkBlue solid;
    outline: none;

    &.shape {
      width: 13rem;
    }

    &.Mui-selected {
      background: linear-gradient(230deg, $darkBlue, $lightBlue);
      border: none;

      & span {
        color: var(--white);
      }
    }

    & span {
      display: block;
      height: 5rem;
      width: 5rem;
      line-height: 5rem;
      color: $darkBlue;
      font-size: 2rem;
    }

    & .shape {
      width: 13rem;

      &.lowercase {
        text-transform: lowercase;
      }
    }

    & .size {
      &-1 {
        font-size: 1.4rem;
      }

      &-2 {
        font-size: 1.7rem;
      }
    }

    & .contrast {
      &-1 {
        opacity: .5;
      }

      &-2 {
        opacity: .75;
      }
    }
  }
}

.MuiTooltip-popper {
  z-index: 10000000!important;
}
