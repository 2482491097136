$darkBlue: #4678F3;
$lightBlue: #64D6EE;
$red: #cc3300;
$orange: #F57F33;
$success: #07bc0c;
$lightSuccess: rgba(7, 188, 12, .7);
$lightLightBlue: rgba(100, 214, 238, .5);
$lightDarkBlue: rgba(70, 120, 243, .5);

:root {
  --dark: #0D0D0D;
  --white: #FFFFFF;
}

  .light-theme  {
    --dark: #0D0D0D;
    --white: #FFFFFF;
  }

  .dark-theme {
    --dark: #FFFFFF;
    --white: #0D0D0D;
  }

