.comparison {
  &-form {
    & .game-radio-container {
      & label.type {
        width: 12rem;
        height: 5rem;
      }
    }
  }

  &-game {
    &-display {
      display: grid;

      &.orientation-1 {
        & .grid {
          justify-content: center;
        }

        &.withGrid {
          border-right: .1rem solid var(--dark);

          & .grid {
            border-left: .1rem solid var(--dark);
          }
        }
      }

      &.orientation-0 {
        margin-top: -8rem;

        grid-template-rows: repeat(10, auto);

        &.easy {
          grid-template-columns: repeat(2, 15svw);
        }

        &.medium {
          grid-template-columns: repeat(2, 28svw);
        }

        &.difficult {
          grid-template-columns: repeat(2, 45svw);
        }

        & > :nth-child(2n) {
          justify-content: flex-end;

          &.grid.group {
            cursor: pointer;

            &.true {
              pointer-events: none;

              & span {
                color: #339900;
              }
            }

            &.false {
              pointer-events: none;

              & span {
                color: $red;
              }
            }

            &:hover {
              &.true {
                pointer-events: none;

                & span {
                  color: #339900;
                }
              }

              &.false {
                pointer-events: none;

                & span {
                  color: $red;
                }
              }

              & span {
                color: $darkBlue;
              }
            }
          }

          &.grid:not(.group) {
            & span {
              cursor: pointer;

              &:hover {
                color: $darkBlue;

                &.true {
                  color: #339900;
                  pointer-events: none;
                }

                &.false {
                  color: $red;
                  pointer-events: none;
                }
              }

              &.true {
                color: #339900;
                pointer-events:none;
              }

              &.false {
                color: $red;
                pointer-events:none;
              }
            }
          }
        }

        & > :nth-child(2n+1) {
          justify-content: flex-start;

          & span {
            cursor: pointer;
            pointer-events:none;
          }
        }

        &.withGrid {
          & .grid {
            border-top: .1rem solid var(--dark);

            &:last-child, &:nth-last-child(2) {
              border-bottom: .1rem solid var(--dark);
            }
          }
        }
      }

      &.orientation-1 {
        grid-template-columns: repeat(10, auto);
      }

      &.orientation-1, &.orientation-2, &.orientation-3 {
        &.easy {
          grid-template-rows: repeat(2, 15svh);
        }

        &.medium {
          grid-template-rows: repeat(2, 25svh);
        }

        &.difficult {
          grid-template-rows: repeat(2, 45svh);
        }

        & > :nth-child(-n+10) {
          align-items: start;
        }

        & > :nth-last-child(-n+10) {
          align-items: end;

          &.grid {
            & span {
              cursor: pointer;

              &:hover {
                color: $darkBlue;

                &.true {
                  color: #339900;
                  pointer-events: none;
                }

                &.false {
                  color: $red;
                  pointer-events: none;
                }
              }

              &.true {
                color: #339900;
                pointer-events:none;
              }

              &.false {
                color: $red;
                pointer-events:none;
              }
            }
          }
        }
      }

      &.orientation-2, &.orientation-3 {
        grid-template-columns: repeat(20, auto);

        & > :nth-child(-n+20) {
          align-items: start;
        }

        & > :nth-last-child(-n+20) {
          align-items: end;
        }

        &.easy,  &.medium {
          margin-top: -8rem;
        }

        & > :nth-last-child(-n+20) {
          &.grid {
            & span {
              cursor: pointer;

              &:hover {
                color: $darkBlue;

                &.true {
                  color: #339900;
                  pointer-events: none;
                }

                &.false {
                  color: $red;
                  pointer-events: none;
                }
              }

              &.true {
                color: #339900;
                pointer-events:none;
              }

              &.false {
                color: $red;
                pointer-events:none;
              }
            }
          }
        }
      }

      & .grid {
        display: flex;
        align-items: center;

        & .content {
          display: flex;
          align-items: center;
          justify-content: center;
          color: var(--dark);
          min-width: 3rem;

          & svg {
            font-size: inherit;
            padding: 0.3rem;

            &.fh {
              transform: scaleX(-1);
            }

            &.fv {
              transform: scaleY(-1);
            }

            &.r45 {
              transform: rotate(45deg);
            }

            &.r135 {
              transform: rotate(135deg);
            }

            &.r142 {
              transform: rotate(142deg);
            }

            &.r52 {
              transform: rotate(52deg);
            }

            &.r180 {
              transform: rotate(180deg);
            }

            &.r90 {
              transform: rotate(90deg);
            }

            &.r270 {
              transform: rotate(270deg);
            }
          }
        }
      }
    }
  }
}
