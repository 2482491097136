.home {
  width: 50rem;
  margin: -5rem auto 0 auto;

  & div {
    color: $darkBlue;
  }

  &-title {
    text-transform: uppercase;
    font-size: 5rem;
    margin-top: -5rem;
  }

  &-subtitle {
    margin-top: 2rem;
    font-size: 3rem;
    display: flex;
    align-items: center;
    column-gap: 2rem;

    & .icon {
      &:hover {
        cursor: pointer;
      }
    }
  }
}