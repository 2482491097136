.profile {
  width: 50rem;
  margin: -5rem auto 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  &-picture {
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-size: 8rem;
    height: 10rem;
    border-radius: .5rem;
    width: 10rem;
    color: var(--white);
    background: linear-gradient(230deg, $darkBlue, $lightBlue);
  }

  &-form {
    width: 50rem;
    margin-top: 5rem;
    display: flex;
    flex-direction: column;

    &-container {
      display: flex;

      & .it-container {
        &:first-child {
          margin-right: 1rem;
        }

        &:last-child {
          margin-left: 1rem;
        }
      }
    }

    &-password {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 24.3rem;

      & .it-container-container {
        width: 70%;
      }
    }
  }

  &-icon {
    margin: 0 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 5rem;
    border-radius: .5rem;
    width: 5rem;
    color: var(--white);
    background: linear-gradient(230deg, $darkBlue, $lightBlue);

    &-error {
      background: linear-gradient(230deg, $lightDarkBlue, $lightLightBlue);

      &:hover {
        cursor: not-allowed;
      }

      & svg:hover {
        cursor: not-allowed;
      }
    }

    & .icon {
      font-size: 3rem;

      & path {
        fill: var(--white);
      }
    }

    &:hover:not(.profile-icon-error) {
      cursor: pointer;
      border: .15rem solid $darkBlue;
      color: $darkBlue;
      background: var(--white);

      & .icon {
        & path {
          fill: url(#linear-gradient);
        }
      }
    }

    &-container {
      margin-top: 2rem;
      display: flex;
      justify-content: center;
    }
  }
}

.switch-box {
  align-self: flex-end;

  &-title {
    font-size: 1.5rem;
    position: relative;
    top: -1rem;
    padding: 0 1rem;
    display: inline;
    background-color: white;
  }

  & .MuiFormControlLabel-label {
    font-size: 1.5rem;
    color: var(--dark)
  }

  & .MuiFormControlLabel-labelPlacementStart, .MuiFormGroup-root {
    max-width: 14.1rem;
  }
}
