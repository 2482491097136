.dropdown {
  &--is-disabled {
    & .dropdown__single-value {
      color: rgba(0, 0, 0, .5)!important;
    }
  }

  &__control {
    min-width: 100%;
    border: none!important;
    border-bottom: .2rem solid var(--dark)!important;
    border-radius: 0!important;
    min-height: auto!important;

    &--is-focused {
      border: none!important;
      border-radius: 0!important;
      box-shadow: none!important;
      border-bottom: .2rem solid $darkBlue!important;
    }

    &--is-disabled {
      background-color: var(--white)!important;
    }
  }

  &__menu {
    margin-top: 1rem!important;
  }

  &__menu-list {
    padding: 0!important;
    border-radius: .5rem!important;
  }

  &__placeholder {
    font-size: 1.5rem!important;
    color: rgba(0, 0, 0, .5)!important;
  }

  &__input-container, &__placeholder {
    margin-bottom: 0!important;
    padding-top: 1.6rem!important;
    padding-bottom: .2rem!important;
  }

  &__single-value, &__input-container {
    color: var(--dark)!important;
    font-size: 1.5rem!important;
  }

  &__value-container {
    padding: 0!important;
    padding-bottom: .2rem!important;
    align-items: flex-end!important;
  }

  &__indicator {
    padding-bottom: 0!important;

    &-separator {
      margin-bottom: .3rem!important;
    }
  }

  &__option {
    padding: 1rem!important;
    font-size: 1.5rem!important;
    background-color: var(--white)!important;
    color: var(--dark)!important;

    &:hover, &--is-focused {
      background-color: $darkBlue!important;
      color: var(--white)!important;
    }
  }
}
