.upgrade {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70rem;
  height: 40.3rem;
  position: relative;
  color: var(--dark);

  &-first {
    display: flex;
    align-items: center;
    margin-bottom: 4rem;

    & .icon {
      margin-right: 1.5rem;
      font-size: 6rem;
      cursor: default;
    }

    &-text {
      margin-left: 1.5rem;
      width: 60rem;
      text-align: justify;

      & p {
        font-size: 1.5rem;
      }

      & span {
        font-size: 1.5rem;
        font-weight: 700;
      }
    }
  }

  &-second {
    margin-top: 4rem;
    display: flex;

    &-display {
      display: block;
    }
  }

  &-block {
    background: linear-gradient(230deg, $darkBlue, $lightBlue);
    width: 18rem;
    border-radius: .5rem;
    margin: 0 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: .15rem solid var(--white);

    &:hover {
      border: .15rem solid $darkBlue;
      background: var(--white);

      & .upgrade-block-text {
        color: $darkBlue;
      }

      & .upgrade-block-icon .upgrade-icon {
        & path {
          fill: url(#linear-gradient);
        }
      }
    }

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1rem;
      height: 10.5rem;
    }

    &-text {
      padding: 1rem;
      height: 6.5rem;
      text-align: center;
      margin-top: 1rem;
      color: var(--white);
      font-size: 1.5rem;
    }
  }

  &-icon {
    font-size: 5rem;

    & path {
      fill: var(--white);
    }
  }

  &-ci {
    width: 50rem;
    height: 18.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: .15rem solid var(--dark);
    border-radius: .5rem;
    padding: 0 2rem;

    &-container {
      margin: 0 1rem;

      & div {
        margin: 1rem;
        height: 2rem;
        display: flex;
        align-items: center;
        font-size: 1.5rem;

        & svg {
          font-size: 1.5rem;
        }
      }

      &-center {
        justify-content: center;
      }
    }
  }

  &-third {
    width: 50rem;
    display: flex;
    margin-top: 2rem;
    height: 5rem;
    justify-content: space-between;
  }

  &-text {
    width: 50rem;
    max-height: 18.3rem;
    height: 18.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: .15rem solid var(--dark);
    border-radius: .5rem;
    padding: 2rem;
    resize: none;
    font-size: 1.5rem;
    background-color: var(--white);
    color: var(--dark)
  }
}

.subscribe {
  &-bloc {
    background: linear-gradient(230deg, $darkBlue, $lightBlue);
    width: 50%;
    text-align: center;
    color: var(--white);
    font-size: 1.5rem;
    font-weight: 700;
    padding: 2rem 0;
    border-radius: .5rem;
    cursor: pointer;
    border: .15rem solid var(--white);

    &:hover {
      border: .15rem solid $darkBlue;
      background: var(--white);
      color: $darkBlue;
    }

    &.loading  {
      border: .15rem solid $darkBlue;
      background: var(--white);
      color: $darkBlue;
      user-select: none;
      cursor: not-allowed;
    }
  }

  &-loader {
    z-index: 1000;
    position: absolute;
    margin-top: -9rem;
    margin-left: 3.35rem;
  }
}

.spf {
  &-container {
    display: flex;

    & .it-container {
      &:first-child {
        margin-right: 1rem;
      }

      &:last-child {
        margin-left: 1rem;
      }
    }
  }

  &-form {
    padding: 3rem 10rem 0 5rem;

    & button {
      width: 80%;
      margin: .5rem 10% 0 10%;

      &:disabled:not(.loading) {
        background: linear-gradient(230deg, rgba(70, 120, 243, 0.5), rgba(100, 214, 238, 0.5));

        &:hover:not(.loading) {
          color: var(--white);
          border-color: var(--white);
          cursor: not-allowed;
        }
      }
    }
  }
}
