.empan-game {
  margin-top: -5rem;

  &-display {
    display: flex;
    align-items: center;
    justify-content: center;

    & .content {
      padding: .5rem 0;
      min-height: 5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--white);
      color: var(--dark);
      z-index: 2;
      position: absolute;
      border: solid .2rem transparent;

      &.left {
        right: 51%;
      }

      &.right {
        left: 51%;
      }
    }

    & .point {
      position: absolute;
      color: var(--dark);
    }
  }
}
