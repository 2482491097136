.linear-stop1 {
  stop-color: rgb(70,120,243);

  &-false {
    stop-color: $red;
  }

  &-true {
    stop-color: $success;
  }
}

.linear-stop2 {
  stop-color: rgb(100, 214, 238);

  &-false {
    stop-color: $orange;
  }

  &-true {
    stop-color: $lightSuccess;
  }
}

.svg-settings {
  position: absolute;
}