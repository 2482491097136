.memory {
  &-game {
    &.noGroup {
      & .rgd-fake-word {
        &-container {
          grid-template-columns: repeat(4, 1fr);
          grid-template-rows: repeat(1, 1fr);
        }

        &-content {
          & span {
            letter-spacing: 0!important;
          }
        }
      }

      & .rgd-real-word.hide {
        & span {
          letter-spacing: 0!important;
        }

        &.hide {
          & svg path {
            fill: var(--white);
          }

          &:hover {
            & span {
              //color: initial !important;
            }

            & svg path {
              fill: currentColor !important;
            }
          }
        }
      }
    }

    &.group {
      &.typeComparison {
        //comparison color
        &-0 {
          & .rgd-real-word.hide {
            & span {
              & span {
                color: var(--white)!important;
              }

              & svg path {
                fill: var(--white);
              }
            }

            &:hover {
              & span {
                & span {
                  color: inherit!important;
                }

                & svg path {
                  fill: currentColor!important;
                }
              }
            }
          }

          & .rgd-fake-word-content {
            &.false span {
              color: $red!important;

              &:hover {
                color: $red!important;
              }
            }

            &.true span {
              color: $success!important;

              &:hover {
                color: $success!important;
              }
            }
          }
        }

        //comparison shape
        &-1 {
          & .rgd-real-word {
            & :last-child {
              letter-spacing: 0 !important;
            }

            &.hide {
              & svg path {
                fill: var(--white);
              }

              &:hover {
                & span {
                  //color: initial !important;
                }

                & svg path {
                  fill: currentColor !important;
                }
              }
            }
          }
        }
      }
    }

    & .rgd-real-word {
      padding: .5rem 1.5rem;

      & :last-child {
        & span {
          letter-spacing: 0!important;
        }
      }

      & svg {
        &.fh {
          transform: scaleX(-1);
        }

        &.fv {
          transform: scaleY(-1);
        }

        &.r45 {
          transform: rotate(45deg);
        }

        &.r135 {
          transform: rotate(135deg);
        }

        &.r142 {
          transform: rotate(142deg);
        }

        &.r52 {
          transform: rotate(52deg);
        }

        &.r180 {
          transform: rotate(180deg);
        }

        &.r90 {
          transform: rotate(90deg);
        }

        &.r270 {
          transform: rotate(270deg);
        }
      }
    }

    & .rgd-fake-word-content {
      &.hide {
        & span {
          color: var(--white)!important;
        }

        & svg path {
          fill: var(--white)!important;
        }
      }

      & svg {
        &.fh {
          transform: scaleX(-1);
        }

        &.fv {
          transform: scaleY(-1);
        }

        &.r45 {
          transform: rotate(45deg);
        }

        &.r135 {
          transform: rotate(135deg);
        }

        &.r142 {
          transform: rotate(142deg);
        }

        &.r52 {
          transform: rotate(52deg);
        }

        &.r180 {
          transform: rotate(180deg);
        }

        &.r90 {
          transform: rotate(90deg);
        }

        &.r270 {
          transform: rotate(270deg);
        }
      }
    }
  }
}
