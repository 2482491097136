.login {
  margin-top: -10rem;
  display: flex;
  align-items: center;

  &-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-right: 5.5rem;
    position: relative;
    height: 31.6rem;

    &::after {
      position: absolute;
      right: 0;
      content:'';
      width: .5rem;
      height: 100%;
      background: linear-gradient(230deg, $darkBlue, $lightBlue);
    }
  }

  &-logo {
    width: 15rem;
    height: 15rem;
  }

  &-name {
    text-align: center;
    font-weight: 700;
    font-size: 5rem;
    text-transform: uppercase;
    margin-top: 4rem;
    color: var(--dark);
  }

  &-form {
    margin-left: 5rem;
    width: 40rem;
    height: 31.6rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &-forgot {
      & .login-signup:last-child {
        margin-top: 2rem;
      }
    }

    & .it-container-dropdown {
      width: 60%;
    }

    & .it-dropdown-error {
      margin-bottom: 1.9rem!important;

      & .dropdown__control {
        border-bottom: .2rem solid $red!important;
      }

      & .dropdown__control--is-focused {
        border-bottom: .2rem solid $darkBlue!important;
      }
    }

    & h1 {
      font-weight: 700;
      font-size: 3rem;
      text-transform: uppercase;
      margin-bottom: 2rem;
      color: var(--dark);
    }

    & form {
      display: flex;
      flex-direction: column;
    }
  }

  &-banner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    &-text {
      width: 100%;
      font-size: 1.5rem;
      font-weight: 900;
      background: $orange;
      color: #FFFFFF;
      padding: .5rem;

      & svg {
        margin-left: 3.2rem;
        margin-right: 1rem;
        font-size: 1.5rem;
      }
    }

    &-icons {
      display: flex;
      justify-content: space-between;
    }
  }

  &-signup {
    font-size: 1.5rem;
    color: var(--dark);

    & span {
      cursor: pointer;
      font-size: 1.5rem;
      color: $darkBlue;

      &:hover {
        color: $lightBlue;
      }
    }
  }
}

.create-password {
  margin-bottom: 4.975rem;
}
