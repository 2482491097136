.scan-game {
  margin-top: -8rem;

  &.setup-0 {
    display: flex;
    justify-content: center;
    align-items: center;

    & .scan-game {
      &-display {
        display: grid;
        grid-template-rows: repeat(10, auto);
        grid-template-columns: repeat(20, auto);
      }
    }

    &.type-2 {
      & .scan-game {
        &-display {
          display: grid;
          grid-template-rows: repeat(10, auto);
          grid-template-columns: repeat(4, auto);
        }
      }
    }
  }

  &.setup-1 {
    & .scan-game {
      &-display {
        display: grid;
        grid-template-rows: repeat(5, auto);
        grid-template-columns: repeat(10, auto);
      }
    }

    &.type-2 {
      & .scan-game {
        &-display {
          display: grid;
          grid-template-rows: repeat(5, auto);
          grid-template-columns: repeat(3, auto);
        }
      }
    }
  }

  &-display {
    & .grid {
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--dark);

      & svg {
        font-size: inherit;
        padding: .3rem;

        &.fh {
          transform: scaleX(-1);
        }

        &.fv {
          transform: scaleY(-1);
        }

        &.r45 {
          transform: rotate(45deg);
        }

        &.r135 {
          transform: rotate(135deg);
        }

        &.r142 {
          transform: rotate(142deg);
        }

        &.r52 {
          transform: rotate(52deg);
        }

        &.r180 {
          transform: rotate(180deg);
        }

        &.r90 {
          transform: rotate(90deg);
        }

        &.r270 {
          transform: rotate(270deg);
        }
      }

      &:hover {
        color: $darkBlue;
        cursor: pointer;
      }

      &.false {
        pointer-events: none;
        color: $red;
      }

      &.true {
        color: $success;
        pointer-events: none;
      }
    }
  }

  &-elementToFind {
    position: absolute;
    bottom: 5rem;
    text-transform: uppercase;
    font-size: 3rem;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 50%;
    transform: translateX(-50%);
    flex-direction: column;

    &-search {
      font-size: 3rem;
      color: var(--dark);

      & span {
        margin-left: .8rem;
        font-size: inherit;
        font-weight: 700;
        color: $darkBlue;

        & svg {
          font-size: inherit;
          padding: .3rem;

          &.fh {
            transform: scaleX(-1);
          }

          &.fv {
            transform: scaleY(-1);
          }

          &.r45 {
            transform: rotate(45deg);
          }

          &.r135 {
            transform: rotate(135deg);
          }

          &.r142 {
            transform: rotate(142deg);
          }

          &.r52 {
            transform: rotate(52deg);
          }

          &.r180 {
            transform: rotate(180deg);
          }

          &.r90 {
            transform: rotate(90deg);
          }

          &.r270 {
            transform: rotate(270deg);
          }
        }
      }
    }

    &-subtitle {
      font-weight: 400!important;
      color: var(--dark)!important;
      font-size: 1.5rem!important;
    }
  }
}
