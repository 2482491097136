.in-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.nlf {
  &-content {
    margin: 1rem 0;

    & > * {
      margin: 0!important;
    }
  }

  &-example {
    border: .15rem solid $darkBlue;
    border-radius: 0.5rem;
    margin-bottom: 2rem;
    padding: .5rem;

    & span {
      color: var(--dark);
    }
  }
}

.it-container {
  position: relative;
  width: 100%;
  margin-bottom: 3rem;
  height: 5.05rem;

  &-dropdown {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: .5rem;
    margin-top: .4rem;

    & .it-error {
      margin-top: 1.1rem!important;
      height: 0;
      position: initial;
    }
  }

  &-container {
    display: flex;
    align-items: center;
    width: 100%;

    & .it-forgot {
      margin-top: -1rem;
      padding-left: 1rem;
      cursor: pointer;
      color: var(--dark);

      svg {
        height: 2.5rem;
      }
    }
  }

  & .it-error {
    margin-left: .2rem;
    margin-top: 5.05rem;
  }

  &.it-container-error {
    & .dropdown div {
      border-color: $red;
    }
  }
}

.it-input {
  width: 100%;
  font-size: 1.5rem;
  padding: .2rem 0;
  margin: 2.2rem 0 .5rem 0;
  border: none;
  border-bottom: .2rem solid var(--dark);
  outline: none;
  background: transparent;
  transition: border-color 0.2s;
  height: 2.35rem;
  position: absolute;
  color: var(--dark);
  z-index: 10;

  &-error {
    border-bottom: .2rem solid $red;
  }

  &::placeholder {
    color: transparent;
  }

  &:placeholder-shown ~ .it-label {
    font-size: 1.5rem;
    top: 2.5rem;
    cursor: text;
  }
}

.it-label {
  font-size: 1.5rem;
  position: absolute;
  top: 0.5rem;
  display: block;
  color: $darkBlue;
  transition: .2s;
  //z-index: -1;
  z-index: 0;
}

.it-input:focus {
  ~ .it-label {
    position: absolute;
    top: 0.5rem;
    display: block;
    color: $darkBlue;
    font-weight: 700;
    transition: .2s;
  }

  border-bottom: .2rem solid $darkBlue;
}

.it-input:disabled {
  //color: rgba(0, 0, 0, .5);
  opacity: .5;
}

.it-error {
  position: absolute;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 1rem;
  color: $red;

  & svg {
    font-size: 1.2rem;
    margin-right: .5rem;

    & path {
      fill: $red
    }
  }
}

.pfc-alone {
  & .it-container {
    margin-left: 0!important;
    margin-right: 0!important;
  }
}
