.game {
  &-form {
    height: 57.5rem;

    & form {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &-radio {
    margin: 0 .5rem;

    &-content {
      display: flex;
    }

    &-title {
      font-size: 1.5rem;
      margin-bottom: .5rem;
    }

    & input:disabled + label {
      opacity: 50%;
      cursor: not-allowed;
    }

    &-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 1rem 0;

      &.hide {
        display: none;
      }

      & label {
        &.type {
          height: 5rem;
          width: 13rem;
        }

        &.difficulty {
          height: 5rem;
          width: 10rem;
        }

        &.number {
          height: 4rem;
          width: 4rem;
        }

        &.time {
          height: 5rem;
          width: 8rem;
        }

        &.textType, &.readText, &.wordType {
          height: 5rem;
          width: 13rem;
        }

        &.textColored {
          height: 5rem;
          width: 16rem;
        }

        &.colored {
          height: 5rem;
          width: 14rem;
        }

        &.orientation, &.stopwatch, &.direction, &.directionV, &.grid, &.setup, &.density, &.group {
          height: 6rem;
          width: 6rem;
        }

        &.numberChar {
          height: 4rem;
          width: 4rem;
        }

        & span {
          color: #4678F3;
          font-size: 1.2rem;

          &.cursive {
            font-size: 2.6rem;
            margin-bottom: 0;
            line-height: 2.4rem;
          }

          &.radio-icon {
            font-size: 2rem;
            font-weight: 700;
            display: flex;
            align-items: center;

            &.horizontal, &.vertical, &.backSlash, &.slash {
              display: block;
              height: .25rem;
              width: 1.8rem;
              margin-top: 2rem;
              margin-bottom: 1.85rem;
              border-radius: .2rem;
              background-color: $darkBlue;
            }

            &.overlay {
              margin-right: -.4rem;

              & svg:first-child {
                transform: translateX(0.625rem);
              }

              & svg:last-child {
                transform: translateX(-0.875rem);
              }
            }

            &.vertical {
              transform: rotate(90deg);
            }

            &.slash {
              transform: rotate(-45deg);
            }

            &.backSlash {
              transform: rotate(45deg);
            }

            & .pipe {
              font-weight: 900;
            }

            & svg {
              font-size: 2rem;

              & path {
                fill: #4678F3;
              }
            }
          }
        }

        cursor: pointer;
        color: #4678F3;
        border: .15rem solid $darkBlue;
        border-radius: .5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 2rem;

        & .rText {
          font-size: 2rem;

          &.rColored {
            color: $red;
          }
        }
      }
    }

    input {
      position: absolute;
      left: -9999px;
    }

    input:checked + label,
    input:checked + label .icon-checkbox,
    label.active,
    label.active .icon-checkbox {
      background: linear-gradient(230deg, $darkBlue, $lightBlue);
      border: .15rem solid var(--white);
      color: white;

      & span {
        color: white;

        &.radio-icon {
          font-size: 2rem;

          &.horizontal, &.vertical, &.slash, &.backSlash {
            background-color: var(--white);
          }

          & svg {
            font-size: 2rem;

            & path {
              fill: white;
            }
          }
        }
      }
    }
  }
}

.hide {
  cursor: pointer;
  border-radius: .5rem;
  background: linear-gradient(0, var(--white), var(--white)) padding-box, linear-gradient(230deg, $darkBlue, $lightBlue) border-box;

  & span {
    color: var(--white);

    &:hover {
      cursor: pointer;
      color: var(--dark);
    }
  }
}

@media screen and (max-width: 560px) {
  .game {
    &-radio {
      &-container {
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        row-gap: 1rem;
      }
    }
  }
}
