.pursuit {
  &-game {
    width: 100%;
  }

  &-form {
    & .rText {
      & span {
        font-size: 2rem;
      }
    }
  }

  &-display {
    min-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    & .blank-0, {
      height: 86svh;
      width: 3svw;
      background-color: var(--white);
      position: absolute;
      z-index: 1000;
      top: 3svh;

      &-first {
        left: 0;
        border-right: .4rem solid var(--dark);
      }

      &-last {
        right: 0;
        border-left: .4rem solid var(--dark);
      }
    }

    & .blank-1 {
      width: 88svw;
      background-color: var(--white);
      position: absolute;
      z-index: 1000;

      &-first {
        height: 3svh;
        top: 0;
        border-bottom: .4rem solid var(--dark);
      }

      &-last {
        height: 11svh;
        bottom: 0;
        border-top: .4rem solid var(--dark);
      }
    }

    & .blank-3 {
      position: absolute;

      &-first, &-last {
        width: calc(88svw + .8rem);
      }

      &-first {
        height: 3svh;
        top: 0;
        border-bottom: .4rem solid var(--dark);
      }

      &-last {
        height: 11svh;
        bottom: 0;
        border-top: .4rem solid var(--dark);
      }

      &-left, &-right {
        top: 3svh;
        height: 86svh;
        width: 6svw;
      }

      &-left {
        left: 0;
        border-right: .4rem solid var(--dark);
      }

      &-right {
        right: 0;
        border-left: .4rem solid var(--dark);
      }
    }
  }
}
