.antiJerk-game {
  &-display {
    display: grid;
    grid-template-rows: repeat(9, calc(100svh / 9));
    grid-template-columns: repeat(15, calc(86svw / 15));

    & .grid {
      display: flex;
      align-items: center;
      justify-content: center;

      & + * {
        color: var(--dark);
      }

      & svg {
        font-size: inherit;
        padding: .3rem 1.5rem;

        &.fh {
          transform: scaleX(-1);
        }

        &.fv {
          transform: scaleY(-1);
        }

        &.r45 {
          transform: rotate(45deg);
        }

        &.r135 {
          transform: rotate(135deg);
        }

        &.r142 {
          transform: rotate(142deg);
        }

        &.r52 {
          transform: rotate(52deg);
        }

        &.r180 {
          transform: rotate(180deg);
        }

        &.r90 {
          transform: rotate(90deg);
        }

        &.r270 {
          transform: rotate(270deg);
        }
      }
    }

    & .content {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      border: solid .2rem transparent;
      padding: .5rem 0;
      min-height: 5rem;
      min-width: 5rem;
      color: var(--dark);

      &.hide {
        & svg {
          color: var(--white)
        }

        &:hover {
          & span, & svg {
            color: var(--dark);
          }
        }
      }

      & span {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.jerk {
  &-form {
    & .position {
      height: 6rem;
      width: 6rem;
    }
  }
}
