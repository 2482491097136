.mobile {
  background: var(--white);
  z-index: 100;
  padding: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  max-height: 100svh;
  max-width: 100svw;

  &-title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  &-logo {
    height: 5rem;
    width: 5rem;
  }

  &-name {
    font-size: 3rem;
    font-weight: 700;
    margin-top: .5rem;
    margin-left: 2rem;
  }

  &-insta {
    display: flex;
    align-self: center;
    margin-top: 2rem;
    align-items: center;

    & svg {
      font-size: 5rem;

      & path {
        fill: var(--dark);
      }
    }

    & p {
      margin-left: 2rem;
      font-size: 1.5rem;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & p {
      font-size: 2rem;
      color: var(--dark);
    }

    & a {
      font-size: 2rem;
      text-decoration: underline;
      color: var(--dark);
    }

    &-center {
      font-size: 2rem;
      color: $darkBlue;
      text-decoration: underline;

      &:hover {
        cursor: pointer;
        color: $lightBlue;
      }
    }
  }
}
